import { isMobile } from "./common";

export default function home_news() {
  
  if (isMobile()) {
    $('.elvis-news-block .news-container').addClass('owl-carousel');
    $('.elvis-news-block .news-container').owlCarousel({
      items: 1.1,
      dotsEach: false,
      margin: 15,
      autoplay: false,
      loop: true,
      smartSpeed: 1600,
      mouseDrag: true,
      touchDrag: true,
      nav: false,
    });
  }

}