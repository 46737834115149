export default function home_videocarousel() {
  $('.elvis-videocarousel-block .owl-carousel').owlCarousel({
    items: 1.2,
    center: true,
    dotsEach: true,
    margin: 20,
    autoplay: false,
    loop: true,
    smartSpeed: 1600,
    mouseDrag: true,
    touchDrag: true,
    nav: false,
    navText: ['<i class="fas fa-arrow-left"></i><span class="sr-only">Prev</span>', '<i class="fas fa-arrow-right"></i><span class="sr-only">Next</span>'],
    responsive: {
      768: {
        items: 3,
        center: false,
        nav: true
      }
    }
  });

  $('.elvis-videocarousel-block .owl-carousel .item a').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    closeMarkup: '<a class="mfp-close"></a>',
    closeOnBgClick: false
  });

  initMarquee();

  function initMarquee() {
    var speed = 60;
    var gap = 0;
  
    if ($(window).width() < 768) {
      speed = 40;
    } else if ($(window).width() < 992) {
      speed = 50;
    }
  
    $('.marquee-text').marquee({
      speed: speed,
      gap: gap,
      duplicated: true,
      delayBeforeStart: 0,
      startVisible: true,
      direction: 'left',
    });
  }
}