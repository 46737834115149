import AOS from 'aos';
import 'owl.carousel';
import 'magnific-popup';
import 'select2';
import '../../node_modules/jquery.marquee/jquery.marquee';

import common from './modules/common';
import home_albumcarousel from './modules/home_albumcarousel';
import home_films from './modules/home_films';
import home_news from './modules/home_news';
import home_videocarousel from './modules/home_videocarousel';

import release_single from './modules/release_single';
import release_index from './modules/release_index';
import film_single from './modules/film_single';
import film_index from './modules/film_index';
import related_posts from './modules/related_posts';
import news_single from './modules/news_single';
import news_index from './modules/news_index';

jQuery( document ).ready(function($) {
  
  common();
  
  // Home

  if ($('body').hasClass('home')) {
    home_albumcarousel();
    home_films();
    home_news();
    home_videocarousel();
  }

  // Release

  if ($('body').hasClass('page-template-music-releases-index')) {
    release_index();
  }

  if ($('body').hasClass('single-release')) {
    release_single();
  }


  // Film

  if ($('body').hasClass('page-template-films-index')) {
    film_index();
  }

  if ($('body').hasClass('single-film')) {
    film_single();
  }

  // News

  if ($('body').hasClass('page-template-news-index')) {
    news_index();
  }

  if ($('body').hasClass('single-post')) {
    news_single();
  }

  related_posts();

  // AOS init
  AOS.init({
    duration: 1000,
    offest: 200,
    anchorPlacement: 'top-bottom'
  });

});