import { initCustomCursor, isMobile } from "./common";

export default function home_films() {
  
  if (isMobile()) {

    $('.elvis-films-block .films-container').addClass('owl-carousel');
    $('.elvis-films-block .films-container').owlCarousel({
      items: 1.2,
      center: true,
      margin: 0,
      autoplay: false,
      loop: true,
      smartSpeed: 1600,
      mouseDrag: true,
      touchDrag: true,
      nav: false,
    });

  } else {
    $('.elvis-films-block .item').draggable();

    $('.elvis-films-block .item').click(function(e){
      e.preventDefault();

      var main_item = $('.elvis-films-block .item-main');
      var mi_image = $(main_item).find('.item-thumb').html();
      var mi_title = $(main_item).find('.item-title').html();
      var mi_links = $(main_item).find('.item-links').html();

      $(main_item).find('.item-thumb').html($(this).find('.item-thumb').html());
      $(main_item).find('.item-title').html($(this).find('.item-title').html());
      $(main_item).find('.item-links').html($(this).find('.item-links').html());

      $(this).find('.item-thumb').html(mi_image);
      $(this).find('.item-title').html(mi_title);
      $(this).find('.item-links').html(mi_links);

      initCustomCursor();
    });
  }

  initCustomCursor();

}