export default function release_single() {
  if (Math.ceil($('.release-desc').height()) + 5 < $('.release-desc')[0].scrollHeight) {
    $('.release-desc-action').show();
  }

  $('.release-desc-action a').click(function(e){
    e.preventDefault();

    $('.release-desc').toggleClass('expanded');

    if ($('.release-desc').hasClass('expanded')) {
      $(this).html('Close');
    } else {
      $(this).html('Read More');
    }
    
  });
}