import { initCustomCursor, isMobile } from "./common";

export default function release_index() {
  
  if (isMobile()) {
    $('.release-list').addClass('mode-list');
  }

  $('#release_filter').select2();

  $('#release_filter').on('select2:select', function (e) {
    filter_releases();
  });

  $('.filters .sort-by ul li a').click(function(e){
    e.preventDefault();

    $('.filters .sort-by').removeClass('selected');
    $(this).closest('.sort-by').data('order', $(this).data('order'));
    $(this).closest('.sort-by').addClass('selected');

    $('.sort-title').data('order', 'asc');
    $('#sort-title').html('A - Z');

    filter_releases();
  });

  $('#sort-title').click(function(e){
    e.preventDefault();

    var _parent = $(this).parent();

    $('.filters .sort-by').removeClass('selected');
    $(_parent).addClass('selected');

    if ($(_parent).data('order') == 'asc') {
      $(_parent).data('order', 'desc');
      $(this).html('Z - A');
    } else {
      $(_parent).data('order', 'asc');
      $(this).html('A - Z');
    }

    filter_releases();
  });

  $('#release_search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_releases();
    }
  });

  function filter_releases() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#release_filter').val() != '' ) {
      params.push( 'release_filter=' + $('#release_filter').val() );
    }

    params.push( 'release_sort=' + $('.sort-by.selected').data('sort') );
    params.push( 'release_order=' + $('.sort-by.selected').data('order') );

    if ( $('#release_search').val() != '' ) {
      params.push( 'release_search=' + $('#release_search').val() );
    }

    $('.release-list').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.release-list').html( $(res).find('.release-list').html() );
        initCustomCursor();
      }
    });
  }

  $('.viewmode a').click(function(e){
    e.preventDefault();

    $('.viewmode a').each(function(){
      $(this).removeClass('active');
      $('.release-list').removeClass($(this).data('mode'));
    });

    $(this).addClass('active');
    $('.release-list').addClass($(this).data('mode'));
  });

}