import { initCustomCursor, isMobile } from "./common";

export default function news_index() {
  
  $('#news_search').keypress(function (e) {
    if ( e.keyCode == 13 ) {
      filter_news();
    }
  });

  function filter_news() {
    var filter_url = $('.filters').data('url');
    var params = [];

    if ( $('#news_search').val() != '' ) {
      params.push( 'news_search=' + $('#news_search').val() );
    }

    $('.page-content').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: filter_url + '?' + params.join('&'),
      type: 'GET',
      success: function(res) {
        $('.page-content').html( $(res).find('.page-content').html() );
        initCustomCursor();
      }
    });
  }

}