export default function related_posts() {

  $('.sub-block-collapse').each(function(){
    var el_block = this;
    var el_title = $(this).find('.sub-block__title');
    el_title.append('<i class="fal fa-plus"></i>');
    var el_icon = el_title.find('i');
    $(el_title).click(function(){
      $(el_icon).toggleClass('fa-plus');
      $(el_icon).toggleClass('fa-times');
      $(el_block).find('.sub-block__content').slideToggle();
    });
  });

  var width = $(window).width();

  // Related Videos

  var count = $('.related-posts-container .video-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 3);

  $('.related-posts-container .video-list').owlCarousel({
    items: 2,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: false,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    autoWidth: false,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
  });

  // Related Photos

  $('.related-posts-container .photo-list .item').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    verticalFit: true,
    closeMarkup: '<img class="mfp-close" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/close.png">',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1],
      arrowMarkup: '<img class="btn-%dir%" src="' + wp.site_url + '/wp-content/themes/milesdavis/dist/img/arrow-%dir%.png">', 
      tCounter: '<span>%curr%</span> / %total%'
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.data('credit');
      }
    },
    callbacks: {
      change: function(){
        console.log($('.mfp-counter span').html());
        if (this.index < 9) {
          var _num = this.index + 1;
          setTimeout(function(){
            $('.mfp-counter span').html('0' + _num);
          }, 10)
          
        }
      }
    }
  });

  var count = $('.related-posts-container .photo-list').data('count');
  var loop = (width < 768 && count >= 2) || (width >= 768 && count >= 4);

	$('.related-posts-container .photo-list').owlCarousel({
    items: 1.3,
    margin: 30,
    dotsEach: true,
    loop: loop,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: false,
    autoplayHoverPause: true,
    navText: ['<i class="fal fa-arrow-left"></i>','<i class="fal fa-arrow-right"></i>'],
    responsive: {
      768: {
        items: 3.5
      }
    }
  });

  $('.related-posts-container .photo-list .owl-item.cloned .item a').click(function(e){
    e.preventDefault();

    var img_src = $(this).attr('href');

    $('.related-posts-container .photo-list .owl-item:not(.cloned)').each(function(){
      var main_a = $(this).find('a');
      if (main_a.attr('href') == img_src) {
        main_a.click();
      }
    });
  });
}