export default function common() {

  if (!$('body').hasClass('home') || isMobile()) {
    $('#main').css('padding-top', $('#header').outerHeight());
  } else {
    $(window).scroll(function(){
      if ($(window).scrollTop() >= $('.homehero-menu').offset().top) {
        $('#header').addClass('show');
      } else {
        $('#header').removeClass('show');
      }
    });
  }

  $(window).scroll(function(){
    if ($(window).scrollTop() >= $('#header').outerHeight()) {
      $('#header').addClass('sticky');
    } else {
      $('#header').removeClass('sticky');
    }
  });
  
  // Menu Open
  $('.site-menu').click(function(e){
    e.preventDefault();

    $('body').addClass('menu-open');
  });

  // Menu Close
  $('#menu-close').click(function(e){
    e.preventDefault();

    $('body').removeClass('menu-open');
  });

  // Voice Modal
  $('.socials .icon-voice a').magnificPopup({
    type: 'inline',
    closeOnBgClick: false,
    closeMarkup: '<a class="mfp-close" href="javscript:void(0)">Close</a>',
  });

  // Social popup open
  $('.share-bar > a.icon-share').click(function(e){
    e.preventDefault();

    $('.share-bar').toggleClass('open');
  });

	$('.share-bar ul li a').click(function(e){
		e.preventDefault();
		const link = $(this).attr("href");
		const w = 670;
		const h = 378;
		const y = window.outerHeight / 2 + window.screenY - (h / 2)
		const x = window.outerWidth / 2 + window.screenX - (w / 2)
		window.open(link, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
	});

  // Subscribe Modal
  /*$('.btn-subscribe, .share-bar .icon-envelope').magnificPopup({
    type: 'inline',
    closeOnBgClick: false,
    closeMarkup: '<span class="mfp-close">Close</span>',
  });*/

  // Dropdown Buttons
  initDropdownLinks();

  // Change Close Button
  $('#save-close, #apple-music-popup-close, #spotify-free-popup-close, #apple-premium-popup-close, #spotify-premium-popup-close').html('Close');

  // Custom Cursor
  initCustomCursor();

}

const initCustomCursor = () => {
  // Custom Cursor
  $('.custom-cursor').each(function(e){
    if ($(this).find('.badge').length == 0) {
      $(this).append('<div class="badge">' + $(this).data('title') + '</div>');
    }
  });

  $('.custom-cursor').hover(function(){
    $(this).addClass('hover');
  }, function(){
    $(this).removeClass('hover');
  });

  $('.custom-cursor').mousemove(function(e){
    console.log(e.pageY);
    if (e.pageX >= $(this).offset().left && e.pageX <= $(this).offset().left + $(this).width() && e.pageY >= $(this).offset().top && e.pageY <= $(this).offset().top + $(this).height()) {
      var _cursor = $(this).find('.badge');
      _cursor.css('left', e.pageX - $(this).offset().left);
      _cursor.css('top', e.pageY - $(this).offset().top);
    } else {
      $(this).removeClass('hover');
    }
  });
}

const isMobile = () => {
  return $(window).width() < 768;
}

const initDropdownLinks = () => {
  $('.dropdown-links > div > a').click(function(e){
    $('.dropdown-links > div > a').not(this).parent().removeClass('open');
    if (!$(this).hasClass('lsap-album-save') && $(this).parent().find('ul').length > 0) {
      e.preventDefault();
      $(this).parent().toggleClass('open');
      $(this).blur();
    }
  });
}

export { initCustomCursor, isMobile, initDropdownLinks };