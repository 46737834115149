export default function film_single() {
  if (Math.ceil($('.film-desc').height()) + 5 < $('.film-desc')[0].scrollHeight) {
    $('.film-desc-action').show();
  }

  $('.film-desc-action a').click(function(e){
    e.preventDefault();

    $('.film-desc').toggleClass('expanded');

    if ($('.film-desc').hasClass('expanded')) {
      $(this).html('Close');
    } else {
      $(this).html('Read More');
    }
    
  });
}