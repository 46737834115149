import textFit from 'textFit';
import { isMobile, initDropdownLinks } from "./common";

export default function home_albumcarousel() {
  var spinningTimeout;

  $('.elvis-albumcarousel-block .owl-carousel').owlCarousel({
    items: 2.2,
    center: true,
    margin: 0,
    autoplay: false,
    loop: true,
    smartSpeed: 1600,
    mouseDrag: true,
    touchDrag: true,
    nav: false,
    navText: ['<i class="fas fa-angle-left"></i><span class="sr-only">Prev</span>', '<i class="fas fa-angle-right"></i><span class="sr-only">Next</span>'],
  });

  updateAlbumInfo($('.music-carousel .owl-item.center'));

  function spinAlbum() {
    $('.music-carousel .owl-item .album-cover').removeClass('spinning');
    $('.music-carousel .owl-item.center .album-cover').addClass('spinning');

    $('.music-carousel .owl-item.center .album-cover').mouseenter(function(e){
      e.preventDefault();

      if (!$(this).hasClass('spinning')) {
        $(this).addClass('spinning');
        setTimeout(function(){
          $('.music-carousel .owl-item .album-cover').removeClass('spinning');
        }, 5050);
      }
    });

    if (spinningTimeout) clearTimeout(spinningTimeout);

    spinningTimeout = setTimeout(function(){
      $('.music-carousel .owl-item .album-cover').removeClass('spinning');
    }, 5050);
  }

  function updateAlbumInfo(_album) {
    $('.album-title').width($('.carousel-wrapper').width() * 0.9);
    $('.album-title').height(Math.max($('.carousel-wrapper').height() * 0.8, 1));

    if (isMobile()) {
      $('.album-title').width($('.carousel-wrapper').width() * 0.7);
      $('.album-title').height(Math.max($('.carousel-wrapper').height() * 0.4, 1));
    }

    $('.album-title').html($(_album).find('.item-info__title').html());
    textFit($('.album-title')[0], { 
      minFontSize: 10, 
      maxFontSize: 300,
      multiLine: true,
      alignVert: true,
      alignHoriz: true
    });

    $('.albumcarousel-container .album-info').hide();
    $('.album-info__desc').html($(_album).find('.item-info__desc').html());
    $('.album-info__links').html($(_album).find('.item-info__links').html());
    $('.albumcarousel-container .album-info').fadeIn(1600);

    $('.dropdown-links > div > a').click(function(e){
      e.preventDefault();
    });

    spinAlbum();
    initDropdownLinks();
  }

  $('.music-carousel .item > a').click(function(e){
    var _owlitem = $(this).parent().parent();
    
    if (!$(_owlitem).hasClass('center')) {
      e.preventDefault();

      if ($(_owlitem).prev().hasClass('center')) {
        $('.music-carousel').trigger('next.owl.carousel');
      } else if ($(_owlitem).next().hasClass('center')) {
        $('.music-carousel').trigger('prev.owl.carousel');
      }

      updateAlbumInfo(_owlitem);
    }    
  });

}